define("okwell/transitions", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {

    var pages = [{ order: 1, name: "index" }, { order: 2, name: "services" }, { order: 3, name: "be-prepared" }, { order: 4, name: "upgrade" }, { order: 5, name: "contact" }, { order: 6, name: "urgent" }];

    var duration = 500;
    var transformations = [{
      pickOld: '.transition',
      use: ['toLeft', { duration: duration }]
    }, {
      pickNew: '.transition',
      use: ['toLeft', { duration: duration }]
    }, {
      use: ['fade', { duration: duration / 2 }]
    }];

    var reverseTransformations = [];
    transformations.forEach(function (transformation) {
      var n = JSON.parse(JSON.stringify(transformation));
      n.use[0] = n.use[0] == "toLeft" ? "toRight" : n.use[0] == "toRight" ? "toLeft" : n.use[0];
      reverseTransformations.push(n);
    });

    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = pages[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var FromPage = _step.value;
        var _iteratorNormalCompletion2 = true;
        var _didIteratorError2 = false;
        var _iteratorError2 = undefined;

        try {
          for (var _iterator2 = pages[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
            var ToPage = _step2.value;


            // don't transition to the same page or
            // repeat a transition declaration (this.reverse)
            if (FromPage.order >= ToPage.order) {
              continue;
            }

            this.transition(this.fromRoute(FromPage.name), this.toRoute(ToPage.name), this.use.apply(this, ['explode'].concat(transformations)), this.reverse.apply(this, ['explode'].concat(reverseTransformations)));
          }
        } catch (err) {
          _didIteratorError2 = true;
          _iteratorError2 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion2 && _iterator2.return) {
              _iterator2.return();
            }
          } finally {
            if (_didIteratorError2) {
              throw _iteratorError2;
            }
          }
        }
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }
  };
});